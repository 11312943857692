@import url('https://fonts.googleapis.com/css2?family=Agdasima');

body {
  Background-color: #101010;
}
.home {
  min-height: 96vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

li::marker {
  color: #7fb241;
}

.approach-2-img {
  position: absolute;
  top: 10%;
  left: -20%;
  width: 66%;
}

.approach-1-img {
  border-radius: 20px 0 0 20px;
  height: 56vh;
}

@media (max-width: 1024px) {
  .approach-1-img {
    height: 42vh !important; 
  }

  .approach-2-img {
    position: absolute;
    top: 10%;
    left: -20%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .approach-2-img {
    position: absolute;
    top: 10%;
    left: -5%;
    width: 62%;
  }

  .approach-1-img {
    height: 40vh;
    /* margin-left: 20px; */
  }
}

.custom-dots li button:before {
  color: #7eb041 !important;
  font-size: 16px !important;
}

.custom-dots li.slick-active button:before {
  /* color: blue !important; */
}

.slick-prev:before,
.slick-next:before {
  color: #67944b;
}

.colortext {
  color: #cecece;
}

.contactbg {
  background-image: url("assets/contact.png");
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #c1ff7869;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  z-index: 1;
}

@media (max-width: 768px) {
  .play-button {
    display: block;
  }

  .video-background{
    position: absolute;
    width: 100%;
    height: 56%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
}

.agdasima {
  font-family: "Agdasima", sans-serif;
  font-weight: 700;
  font-style: normal;
}

/* In your CSS file or a CSS module */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 9999; /* Ensure it’s on top of other content */
}

.dialog-panel {
  position: relative;
  z-index: 10000; /* Ensure the panel is on top of the overlay */
}

.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 0px 0px;
  height: auto;
  cursor: text;
  font-size: 100%;
  line-height: 1.5;
  color: #ffffffda;
  background-color: #ffffff00;
  background-image: none;
  border: 0px solid #d9d9d9;
  border-radius: 4px;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rc-time-picker-clear {
  padding-top: 7px;
  position: absolute;
  height: 100%;
}

.rc-time-picker-input::placeholder {
  color: #ffffffda;
  ;
  opacity: 1; /* Firefox */
}

.rc-time-picker-input::-ms-input-placeholder { /* Edge 12 -18 */
  color:  #ffffffda;
}

.submit-bg {
  background-color: #3a511e;
}